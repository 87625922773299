import * as React from 'react';
import {ARCSelectInput} from "./design-system/ARCSelectInput";
import {LOCALE_EN_US, LOCALE_ES_MX, LOCALE_FR_CA, locale_type, LOCALES} from "../../dtos/API_DOMAINS";
import {useAppDispatch} from "../../store/hooks";
import {updateLocale} from "../app-context/appContextSlice";
import {getLocalizedString} from "../../util/Localization";

export const LocaleSelect = () => {
    const dispatch = useAppDispatch();

    const currentLocale = localStorage.getItem("userPrefLocale");

    const getLocaleLabel = React.useCallback((localeString : locale_type) => {
        switch (localeString) {
            case LOCALE_EN_US:
                return {
                    label: getLocalizedString('locale.en', 'English (en)'),
                    selectedLabel: getLocalizedString('locale.en.short', 'en')
                }
            case LOCALE_ES_MX:
                return {
                    label: getLocalizedString('locale.es', 'Español (es)'),
                    selectedLabel: getLocalizedString('locale.es.short', 'es')
                }
            case LOCALE_FR_CA:
                return {
                    label: getLocalizedString('locale.fr', 'Français (fr)'),
                    selectedLabel: getLocalizedString('locale.fr.short', 'fr')
                }
            default:
                return {
                    label: localeString,
                    selectedLabel: localeString
                };
        }
    }, []);

    const onLocaleSelect = React.useCallback((localeString: locale_type) => {
        localStorage.setItem("userPrefLocale", localeString);
        window.locale = localeString;
        dispatch(updateLocale({newLocale: localeString}));
        window.location.reload();
    }, [dispatch]);

    const LocaleOptions = React.useMemo(() => {
        return LOCALES.map(loc => {
            const {label, selectedLabel} = getLocaleLabel(loc);

            return {
                value: loc,
                label: label,
                selectedLabel: selectedLabel
            }
        })
    }, [getLocaleLabel]);

    return (
        <ARCSelectInput id={'locale-select-input'} size={'sm'} filled value={currentLocale} options={LocaleOptions} onChange={(option) => {onLocaleSelect(option.value)}} icon={'globe'}/>
    )
}