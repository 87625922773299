import * as React from 'react';
import {SideBar} from "../../sidebar/SideBar";
import AppBar from "../../appbar/AppBar";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    employeeContextActions,
    employeeContextSelector, getEmployeeImportResult, getEmployeeImportStatuses, getEmployeeUploadTemplateHeader
} from "../employeeSlice";
import {AppWithFooter} from "../../../App";
import {EmployeeValidationDto} from "../../../util/EmployeeFileUploadValidation";
import {ImportChildComponentNames, ImportUploadFile} from "../../ui-components/ImportComponents/ImportUploadFile";
import {ImportLanding} from "../../ui-components/ImportComponents/ImportLanding";
import {ValidatingImport} from "../../ui-components/ImportComponents/ValidatingImport";
import {ReviewImportData} from "../../ui-components/ImportComponents/ReviewImportData";
import {ImportUploadData} from "../../ui-components/ImportComponents/ImportUploadData";
import {appContextSelector} from "../../app-context/appContextSlice";
import {EmployeeNumberComposition} from "../../../dtos/Brand";
import {getLocalizedString} from "../../../util/Localization";

export const ImportEmployees = () => {
    const dispatch = useAppDispatch();
    const employeeContext = useAppSelector(employeeContextSelector);
    const context = useAppSelector(appContextSelector);

    React.useEffect(() => {
        if (context.currentLocation?.locationId && context.currentLocation?.locationId !== -1) {
            dispatch(getEmployeeUploadTemplateHeader({locationId: context.currentLocation?.locationId}))
        }
        return () => {dispatch(employeeContextActions.resetImportEmployee())}
    }, [dispatch, context.currentLocation?.locationId])

    const [uploadFile, setUploadFile] = React.useState<File | undefined>(undefined);
    const [employeeValidationDto, setEmployeeValidationDto] = React.useState<EmployeeValidationDto | undefined>(undefined);

    const label = context.currentBrand?.badgeValidation ? getLocalizedString('employee.badgeId', 'Badge ID') : getLocalizedString('employee.employeeNumber', 'Employee ID')

    const badgeIdNumberRequirements = React.useMemo(() => {
        let returnString = '';
        if (context.currentBrand?.employeeNumberLengthMin) {
            if (context.currentBrand?.employeeNumberLengthMax) {
                if (context.currentBrand?.employeeNumberLengthMin === context.currentBrand?.employeeNumberLengthMax) {
                    returnString = getLocalizedString('employee.employeeNumber.invalidLength', `${label} must be ${context.currentBrand?.employeeNumberLengthMin} characters`, [`${label}`, `${context.currentBrand?.employeeNumberLengthMin}`]);;
                } else {
                    returnString = getLocalizedString('employee.employeeNumber.invalidMinAndMaxLength', `${label} must be between ${context.currentBrand?.employeeNumberLengthMin} and ${context.currentBrand?.employeeNumberLengthMax} characters`, [`${label}`, `${context.currentBrand?.employeeNumberLengthMin}`, `${context.currentBrand?.employeeNumberLengthMax}`]);
                }
            } else {
                returnString = getLocalizedString('employee.employeeNumber.invalidLengthMin', `${label} must be at least ${context.currentBrand?.employeeNumberLengthMin} characters`, [`${label}`, `${context.currentBrand?.employeeNumberLengthMin}`]);
            }
        }

        if (context.currentBrand?.employeeNumberComposition) {
            if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.NUMERIC) {
                returnString += ' ' + getLocalizedString('employee.employeeNumber.composition.numeric', 'and only contain numbers');
            } else if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.ALPHANUMERIC) {
                returnString += ' ' + getLocalizedString('employee.employeeNumber.composition.alphanumeric', 'and only contain numbers and letters');
            }
        }
        if (returnString.length > 0) {
            return returnString;
        } else {
            return undefined
        }
    }, [context.currentBrand?.employeeNumberLengthMin, context.currentBrand?.employeeNumberComposition, context.currentBrand?.employeeNumberLengthMax, label]);

    const onFileChange : React.ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
        const file: File | undefined = event.target.files?.[0];
        if (!file) {
            return;
        }
    
        const validFileTypes = ['text/csv', 'application/vnd.ms-excel'];

        if (!validFileTypes.includes(file.type)) {
            console.error('Invalid file type. Please select a CSV file.');
            return;
        }
        if (file) {
            setUploadFile(file);
        }
    }, []);

    React.useEffect(() => {
        if(employeeContext.downloadTemplateFile) {
            const link = document.createElement('a');
            link.href = employeeContext.downloadTemplateFile;
            link.setAttribute('download', 'user-import-template.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [employeeContext.downloadTemplateFile]);
    
    const previousStatus = React.useMemo(() => {
        if (employeeContext.importStatuses && employeeContext.importStatuses.length > 0) {
            const previousStatus = employeeContext.importStatuses[0];
            const previousStatusDate = new Date(previousStatus.createDateTime);
            const now = new Date();
            const timeDifference = now.getTime() - previousStatusDate.getTime();
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
            if (daysDifference < 1) {
                return previousStatus;
            }
        }
        return undefined;
    }, [employeeContext.importStatuses]);
    
    const [currentChildComponent, setCurrentChildComponent] = React.useState<string>(ImportChildComponentNames.importLanding);
    
    const getPreviousStatusAndNavigateToUploadFile = React.useCallback(() => {
        const locationId = context.currentLocation?.locationId
        if (locationId) {
            dispatch(getEmployeeImportStatuses({locationId: locationId})).then((statuses) => {
                if (statuses.payload) {
                    if (statuses.payload.length > 0) {
                        dispatch(getEmployeeImportResult({
                            locationId: locationId,
                            portalUploadStatusId: statuses.payload[0].portalUploadStatusId
                        })).then(() => {
                            setCurrentChildComponent(ImportChildComponentNames.uploadFilePage);
                        })
                    } else {
                        setCurrentChildComponent(ImportChildComponentNames.uploadFilePage);
                    }
                }
            })
        }
    }, [context.currentLocation?.locationId, dispatch])
    
    const ImportEmployeeChildComponents = React.useMemo(() => {
        switch (currentChildComponent) {
            case ImportChildComponentNames.importLanding:
                return (
                    <ImportLanding 
                        isDeviceImport={false}
                        getPreviousStatusAndNavigateToUploadFile={getPreviousStatusAndNavigateToUploadFile}
                    />
                )
            case ImportChildComponentNames.uploadFilePage:
                return (
                    <ImportUploadFile
                        isDeviceImport={false}
                        setCurrentChildComponent={setCurrentChildComponent}
                        onFileChange={onFileChange}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        previousStatus={previousStatus}
                        idNumberRequirements={badgeIdNumberRequirements}
                    />
                )
            case ImportChildComponentNames.validatingImport:
                return (
                    <ValidatingImport
                        isDeviceImport={false}
                        setCurrentChildComponent={setCurrentChildComponent}
                        uploadFile={uploadFile}
                        deviceValidationDto={undefined}
                        setDeviceValidationDto={undefined}
                        employeeValidationDto={employeeValidationDto}
                        setEmployeeValidationDto={setEmployeeValidationDto}
                        getPreviousStatusAndNavigateToUploadFile={getPreviousStatusAndNavigateToUploadFile}
                    />
                )
            case ImportChildComponentNames.reviewData:
                return (
                    <ReviewImportData
                        isDeviceImport={false}
                        setCurrentChildComponent={setCurrentChildComponent}
                        deviceValidationDto={undefined}
                        employeeValidationDto={employeeValidationDto}
                        getPreviousStatusAndNavigateToUploadFile={getPreviousStatusAndNavigateToUploadFile}
                        idNumberRequirements={badgeIdNumberRequirements}
                    />
                )
            case ImportChildComponentNames.importUploadData:
                return (
                    <ImportUploadData
                        isDeviceImport={false}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        previousStatus={previousStatus}
                        getPreviousStatusAndNavigateToUploadFile={getPreviousStatusAndNavigateToUploadFile}
                    />
                )
        }
        return (
            <ImportLanding 
                isDeviceImport={false}
                getPreviousStatusAndNavigateToUploadFile={getPreviousStatusAndNavigateToUploadFile}
            />
        )
    }, [currentChildComponent, onFileChange, uploadFile, employeeValidationDto, previousStatus, getPreviousStatusAndNavigateToUploadFile, badgeIdNumberRequirements]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {ImportEmployeeChildComponents}
                </div>
            </main>
        </AppWithFooter>
    )
}
