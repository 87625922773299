import React, { useState } from 'react';
import "./ARCSearchSelect.css"
import {ARCTextFilterInput} from "../ARCTextFilterInput";

interface Option {
    name: string,
    value: string
}

interface ARCSearchSelectProps {
    options: Option[];
    disabledOnSingleOption?: boolean;
    placeHolder?: string;
    value?: string | undefined;
    disabled?: boolean;
    onSelect: (selectedOption: string) => void;
    id?: string;
    label?: string;
}

export const ARCSearchSelect: React.FC<ARCSearchSelectProps> = ({
   options,
   disabledOnSingleOption,
   placeHolder,
   value,
   disabled,
   onSelect,
   id,
   label
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState<string | null>(options.find(opt => opt.value === value)?.name ?? null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const hiddenSizerRef = React.useRef<HTMLDivElement>(null);

    const inputDisabled = React.useMemo(() => {
        return disabled || (disabledOnSingleOption && options.length === 1);
    }, [disabled, disabledOnSingleOption, options.length]);

    const handleSearchInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    const handleOptionClick = React.useCallback((option: Option) => {
        if (!inputDisabled) {
            setSelectedOption(option.name);
            setIsOpen(false);
            setSearchQuery('');
            onSelect(option.value);
        }
    }, [onSelect, inputDisabled]);

    const filteredOptions = React.useMemo(() => {
        return options.filter((option) =>
            option.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [options, searchQuery]);

    const handleDocumentClick = React.useCallback((event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        } else {
            if(!inputDisabled) {
                setIsOpen(true);
            }
        }
    }, [inputDisabled]);

    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    React.useEffect(() => {
        if(options.length === 1) {
            handleOptionClick(options[0]);
        }
    }, [handleOptionClick, options]);
    
    const inputValue = React.useMemo(() => {
        if (isOpen) {
            return searchQuery;
        } else {
            return selectedOption ?? placeHolder ?? undefined;
        }
    }, [isOpen, searchQuery, selectedOption, placeHolder])

    const longestOptionWidth = React.useMemo(() => {
        const longestOption = options.reduce((longest, option) => option.name.length > longest.length ? option.name : longest, "");

        if(hiddenSizerRef.current) {
            hiddenSizerRef.current.textContent = longestOption;

            return hiddenSizerRef.current.offsetWidth + 70;
        }

    }, [options]);

    return (
        <div className={'o-search-select'} id={id}>
            {label &&
            <label className={`o-select__label`}>
                {label}
            </label>
            }
            <div className={'o-search-select__container'}>
                <div ref={containerRef}>
                    <div
                        ref={hiddenSizerRef}
                        style={{
                            position: "absolute",
                            visibility: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: ".875rem",
                            padding: "8px",
                        }}
                    />

                    <ARCTextFilterInput 
                        id={'search-select-text-filter'}
                        value={inputValue}
                        onChange={handleSearchInputChange} 
                        onClear={() => setSearchQuery('')}
                        onSearch={() => {}}
                        showXForClear={true}
                        disabled={inputDisabled}
                        inputStyle={{width: `${longestOptionWidth}px`}}
                    />
                </div>
                {!inputDisabled &&
                <div className={`o-search-select__dropdown ${isOpen ? 'active' : ''}`}>
                    {filteredOptions.map((option) => (
                        <div className={'o-search-select__dropdown__option'} key={option.value} onClick={() => handleOptionClick(option)}>
                            {option.name}
                        </div>
                    ))}
                </div>
                }
            </div>
        </div>
    );
};