import axios, {AxiosResponse} from 'axios';
import {getLocale} from "../util/Localization";

type env_type = 'test' | 'qa' | 'production' | 'preview' | 'development';
export const LOCALE_EN_US = 'en_US';
export const LOCALE_FR_CA = 'fr_CA';
export const LOCALE_ES_MX = 'es_MX';

export type locale_type = typeof LOCALE_EN_US | typeof LOCALE_FR_CA | typeof LOCALE_ES_MX;

export const LOCALES : locale_type[] = [
    LOCALE_EN_US,
    LOCALE_FR_CA,
    // LOCALE_ES_MX
]
//This config comes from a config.js file that should be in the public dir of the app
export interface RuntimeConfig {
    portal_app_env: string,
    server_url: string,
    media_url: string,
    locale: string
}
declare const window: any;

export const runtimeConfig: RuntimeConfig = window.runtimeConfig;

export const env : env_type = (runtimeConfig ? runtimeConfig.portal_app_env : 'development') as env_type;
export const server_url = runtimeConfig.server_url;
export const media_url = runtimeConfig.media_url;
export const locale : locale_type = (runtimeConfig ? runtimeConfig.locale || 'en_US' : 'en_US') as locale_type;
let API_DOMAIN = server_url;

// switch (env) {
//     case 'development': {
//         API_DOMAIN = 'http://localhost:8084';
//         break;
//     }
//     case 'production': {
//         API_DOMAIN = 'https://client.arc-kiosk.com/portalserver';
//
//         break;
//     }
//     case 'test': {
//         API_DOMAIN = 'http://localhost:8084';
//         break;
//     }
//     case 'preview': {
//         API_DOMAIN = 'https://preview-clientportal-boh.krc.mn/portalserver';
//         break;
//     }
//     case 'qa' : {
// //        API_DOMAIN = 'https://qaportalserver-boh.krc.mn';
//         API_DOMAIN = 'https://qaclientportal-boh.krc.mn/portalserver';
//         break;
//     }
// }

const API = {
    API_DOMAIN
};
export default API;

export async function GET<DATA> (endpoint: string, params?: unknown) : Promise<AxiosResponse> {
    const token = localStorage.getItem('token');

    if (params) {
        return axios.get<DATA>(`${API_DOMAIN}${endpoint}`, {
            params: params,
            headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : getLocale()},
            paramsSerializer,
        });
    }
    return axios.get<DATA>(`${API_DOMAIN}${endpoint}`, {
        headers: {"Authorization": `Bearer ${token}`, "User-Pref-Locale" : getLocale()}
    });

}

export async function POST<DATA> (endpoint: string, params?: any, body?: unknown) : Promise<AxiosResponse> {
    const token = localStorage.getItem('token');

    if(params && body) {
        return axios.post<DATA>(`${API_DOMAIN}${endpoint}`,
            body,
            {
                params: params ? params : null,
                headers: {"Authorization" : `Bearer ${token}`, "User-Pref-Locale" : getLocale()},
                paramsSerializer,
            }
        );
    }
    if(params) {
        return axios.post<DATA>(`${API_DOMAIN}${endpoint}`, {}, {
            params: params,
            headers: {"Authorization" : `Bearer ${token}`, "User-Pref-Locale" : getLocale()},
            paramsSerializer,
        });
    }
    if(body) {
        return axios.post<DATA>(`${API_DOMAIN}${endpoint}`,
            body, {
                params: params,
                headers: {"Authorization" : `Bearer ${token}`, "User-Pref-Locale" : getLocale()},
                paramsSerializer,
            }
        );
    }
    return axios.post<DATA>(`${API_DOMAIN}${endpoint}`);
}

export const paramsSerializer = (params:any)  => {
    // Sample implementation of query string building
    // console.log({params});
    let result = '';
    Object.keys(params).forEach(key => {
        result += `${key}=${encodeURIComponent(params[key])}&`;
    });

    // console.log({result: result.substr(0, result.length - 1)});
    return result.substr(0, result.length - 1);
};
